import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import * as Tone from 'tone'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Fade from 'react-reveal/Fade'
import Pads from '../Pads'
import DrumSampler from '../DrumSampler'
import About from '../../components/About'

import {
  pads,
  samplerInfo,
  plyrConfig,
  videoUrl,
  hints,
  drumsConfig,
  BASE_S3_URL,
} from '../../config'
import { isSSR, useKeyboradListener, playWithKey } from '../../utils'
import styles from './style.module.css'

let player

const VideoContainer = ({ setMenuHidden }) => {
  const containerRef = useRef(null)
  const [error, setError] = useState(null)
  const [drumPlayer, setDrumPlayer] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [currentLoop, setCurrentLoop] = useState('loop1')
  const keyPressed = useKeyboradListener()
  const [activeInfoPage, switchPage] = useState(true)

  useEffect(() => {
    if (keyPressed) {
      handlePress(keyPressed)
    }
  }, [keyPressed])

  const handlePress = e => {
    if (e.keyCode >= 49 && e.keyCode <= 52) {
      const loop = drumsConfig.find(l => l.title === e.key)
      if (loop && drumPlayer) {
        return changeLoop(loop.name)
      }
    }
    if (e.code === 'Space') {
      e.stopPropagation()
      e.preventDefault()
      return toggleLoop()
    }
    playWithKey(e.keyCode, pads, seekTime)
  }

  const notify = () =>
    !window.mobileAndTabletCheck() &&
    toast(<div className={styles.toast}>{hints.toast}</div>, {
      toastId: '1',
      delay: 4000,
    })

  useEffect(() => {
    if (isSSR()) return

    initDrums()

    const Plyr = require('plyr')
    player = new Plyr('#player', plyrConfig)

    player.on('ready', onPlayerReady)
    window.mobileAndTabletCheck = function () {
      let check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    }
  }, [])

  function onPlayerReady(event) {
    if (player) {
      player.loop = true
      player.quality = 480
      player.autoplay = false
      player.muted = false
      player.volume = 70
      player.on('playing', event => {
        setMenuHidden(true)
      })
    }

    setError(false)
  }

  useEffect(() => {
    if (error !== false || isSSR()) return

    !isSSR() &&
      window.addEventListener('keydown', e => {
        if (e.code == 'Space' && player) {
          e.preventDefault()
          player.togglePlay()
        }
      })
  }, [error])

  const initDrums = () => {
    const urls = drumsConfig.reduce(
      (ac, el) => ({ ...ac, [el.name]: el.src }),
      {}
    )
    const players = new Tone.Players({
      urls,
      fadeOut: '64n',
      onload: handleLoaded,
      baseUrl: BASE_S3_URL,
    }).toDestination()

    setDrumPlayer(players)
  }

  const stopVideo = () => player && player.stop()

  const playVideo = () => player && player.play()

  const seekTime = time => {
    if (player) {
      player.currentTime = time
      player.muted = false
      player.paused && playVideo()
    }
  }

  const handleLoaded = () => {
    setLoaded(true)
  }

  const startLoop = value => {
    drumPlayer.player(value || currentLoop).start()
    drumPlayer.player(value || currentLoop).loop = true
    player.play()
    setPlaying(true)
  }

  const stopLoop = () => {
    drumPlayer.player(currentLoop).stop()
    player.pause()
    setPlaying(false)
  }

  const changeLoop = value => {
    stopLoop()
    setCurrentLoop(value)
    startLoop(value)
  }

  const toggleLoop = () => {
    if (!player || !drumPlayer) return

    if (playing) {
      stopLoop()
      setMenuHidden(false)
    } else {
      startLoop()
      setMenuHidden(true)
    }
  }

  const videoAbout = (
    <div className={styles.samplerAbout}>
      {activeInfoPage ? (
        <>
          <h1>{samplerInfo.title}</h1>
          <h2>{samplerInfo.description}</h2>
          <h2>{samplerInfo.description2}</h2>
        </>
      ) : (
        <>
          <h2 className={styles.moviesAbout}>{samplerInfo.moviesAbout}</h2>
          <h2>{samplerInfo.ads}</h2>
        </>
      )}

      <button className={styles.switchPageBtn} onClick={() => switchPage(!activeInfoPage)}>
        {activeInfoPage ? 'список фильмов' : 'о семплере'}
      </button>
    </div>
  )

  return (
    <div>
      <div
        id="sampler"
        className={cn(
          styles.embedResponsive,
          styles.sampler,
          styles.embedResponsive4by3
        )}
        ref={containerRef}
      >
        {drumPlayer && (
          <DrumSampler
            changeLoop={changeLoop}
            playing={playing}
            currentLoop={currentLoop}
            toggleLoop={toggleLoop}
          />
        )}
        
        <div className={styles.embedResponsiveItem}>
          <video id="player" controls playsInline>
            <source src={videoUrl} type="video/mp4" />
          </video>
        </div>
        <ToastContainer
          className={styles.toaster}
          position="bottom-center"
          autoClose={10000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {error === false && (
          <Pads playerHandler={seekTime} notify={notify} pads={pads} />
        )}
      </div>
      <About 
        onOpen={opened => {
          let hide = !opened
          if (!hide && window.innerWidth <= 600 && (window.innerWidth / window.innerHeight) < 1) {
            hide = true
          } else if (hide && !playing) {
            hide = false
          }
          setMenuHidden(hide)
        }} 
        content={videoAbout} 
        buttonText={samplerInfo.aboutBtn} 
      />
    </div>
  )
}

export default VideoContainer
