import React from 'react'
import cn from 'classnames'

import PauseIcon from './Icons/PauseIcon'
import PlayIcon from './Icons/PlayIcon'

import { drumsConfig } from '../../config'

import styles from './style.module.css'

const DrumSampler = ({ changeLoop, currentLoop, playing, toggleLoop }) => {
  return (
    <div className={styles.container}>
      <div id="drums" className={styles.switchWrap}>
        <div
          className={cn(styles.button, styles.playButton)}
          onClick={toggleLoop}
        >
          {playing ? (
            <PauseIcon width="32px" height="32px" />
          ) : (
            <PlayIcon width="32px" height="32px" />
          )}
        </div>
        {drumsConfig.map(item => (
          <div
            className={cn(styles.button, styles.switchButton, {
              [styles.active]: currentLoop === item.name,
            })}
            onClick={() => changeLoop(item.name)}
            key={item.name}
          >
            {item.title}
          </div>
        ))}
      </div>
    </div>
  )
}

export default DrumSampler
