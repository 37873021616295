import katenv from '../images/katenv.png'
import chinavat from '../images/chinavat.png'
import intourist from '../images/intourist.png'
import pant from '../images/pant.png'
import ceo from '../images/microlee.png'
import eda from '../images/eda-main.png'

export const BASE_S3_URL =
  'https://ya-playtronica-test-bucket.s3.eu-central-1.amazonaws.com/'

export const pads = [
  { note: 'C', key: 'q', time: '00:01' },
  { note: 'C#', key: 'w', time: '00:10' },
  { note: 'D', key: 'e', time: '00:15' },
  { note: 'D#', key: 'r', time: '00:16' },
  { note: 'E', key: 't', time: '00:19' },
  { note: 'F', key: 'y', time: '00:24' },
  { note: 'F#', key: 'a', time: '00:29' },
  { note: 'G', key: 's', time: '00:32' },
  { note: 'G#', key: 'd', time: '00:39' },
  { note: 'A', key: 'f', time: '00:43' },
  { note: 'A#', key: 'g', time: '00:21' },
  { note: 'B', key: 'h', time: '00:49' },
]

export const plyrConfig = {
  controls: ['mute', 'volume', 'airplay', 'fullscreen'],
  muted: false,
  autoplay: false,
  volume: 0.8,
  clickToPlay: true,
  quality: { default: 480, options: [1080, 720, 576, 480, 360, 240] },
  keyboard: { focused: false, global: false },
  loop: { active: true },
}

export const keysAllowed = [
  'q',
  'w',
  'e',
  'r',
  't',
  'y',
  'a',
  's',
  'd',
  'f',
  'g',
  'h',
]

export const headerInfo = {
  title: 'Песни для любимых блюд',
  description:
    'Проект сервиса «Яндекс.Еда» и фруктово-овощных музыкальных инструментов Playtronica.',
}

export const introInfo = [
  'Музыканты за новогодним столом рассказали о любимой еде и сыграли ей серенаду на плейтроне. Подключайтесь к празднику и создайте свою новогоднюю мелодию на музыкальном семплере, в который мы загрузили звуки из культовых фильмов.',
]

export const samplerInfo = {
  moviesAbout:
    '«Ирония судьбы, или С лёгким паром!» (СССР, реж. Эльдар Рязанов), «Иван Васильевич меняет профессию» (СССР, реж. Леонид Гайдай), «Реальная любовь» (Великобритания, реж. Ричард Кёртис), «Полярный экспресс» (США, реж. Роберт Земекис), «Гарри Поттер и философский камень» (Великобритания, реж. Крис Коламбус), «Ёлки 2» (Россия, реж. Дмитрий Киселёв), «Офис» (США, реж. Пол Фиг), «Операция «Ы» и другие приключения Шурика» (СССР, реж. Леонид Гайдай), «Хроники Нарнии: Лев, колдунья и волшебный шкаф» (США, реж. Эндрю Адамсон), «Мистер Бин» (Великобритания, реж. Джон Биркин).',
  aboutBtn: 'о семплере',
  title: 'сыграй свой трек',
  ads: 'Эти фильмы можно посмотреть на Кинопоиск HD с подпиской Яндекс.Плюс.',
  description:
    'Прежде чем подключать провода к любимой еде, потренируйтесь на блюдах из новогодней киноклассики. Мы пересмотрели наши любимые праздничные фильмы — от «Иронии Судьбы» до рождественского эпизода «Офиса» — и сделали телесемплер.', 
    description2:
    'Что делать: выбирайте грув, входите в ритм и жмите на клавиши — на экране появятся знакомые герои и угощения. Несколько попыток, и фраза «Какая гадость эта ваша заливная рыба» превратится в хип-хоп зарисовку.',
}

export const padColors = [
  '#FF5449',
  '#A4DD5C',
  '#FF965A',
  '#92BDFF',
  '#FF8787',
  '#58BFE0',
  '#58BFE0',
  '#FF8787',
  '#92BDFF',
  '#FF965A',
  '#A4DD5C',
  '#FF5449',
]

export const hints = {
  toast: 'Можно играть не только на экране, но и клавиатуре вашего компьютера!',
}
export const tracksConfig = [
  {
    trackUrl: '',
    title: 'холодец',
    artist: 'Интурист',
    description:
      'Импровизатор Женя Горбунов исполнил философскую песню с холодцом — любимчиком гурманов и загадкой новогодних столов. Тая от прикосновений артиста, желе издаёт звуки перкуссии и синтезаторных пэдов. Совет: выносите холодец к гостям в разгар застолья, и будут гостям танцы.',
    videoId: 'ZYyzUzr_A_s',
    bgColor: '#FFB89A',
    iconUrl: '',
    position: 'top',
    infoButtonText: 'о песне',
  },
  {
    trackUrl: '',
    title: 'красная икра',
    artist: 'Kate NV',
    position: 'top',
    description:
      'Новогодний стол Кати Шилоносовой, автора проекта Kate NV, заставлен красной икрой. Хрустальные чаши с деликатесом заменяют артистке семплер и винтажный синтезатор. Мелодии складываются трепетные — как в добром советском кино или эстрадных песнях Юрия Чернавского. Кстати, на съёмках ни одна икринка не пострадала — мы использовали муляж. Но, если брать в Яндекс.Еде, конечно, приедет настоящая.',
    videoId: 'BZqw8Ih1NKU',
    bgColor: '#F5CBC1',
    iconUrl: '',
    position: 'top',
    infoButtonText: 'о песне',
  },
  {
    trackUrl: '',
    title: 'мой о. (страсть)',
    artist: 'Дмитрий Пантюшин',
    description: 
    'Дима Пантюшин талант разных сторон – художник-оформитель, он рисует много плакатов, с друзьями заведует одним кафе, бистро и небольшим уютным бильярдным домом, пишет стихи, поёт и сочиняет музыку вместе со своим другом Сашей Липским. В следующем году у артистов выходит дебютный альбом про общечеловеческие истины. Для «Еда поёт» Дима написал песню о страстной любви к салату оливье — блюду, которое возвращает музыканта к детскому ощущению праздника и семейного уюта. Подыгрывают: сельдь, мандарины, зефир и музыкант Playtronica Виктор Глазунов.',
    videoId: 'eif7TH7gpjU',
    bgColor: '#94C7D2',
    position: 'top',
    iconUrl: '',
    infoButtonText: 'о песне',
  },
  {
    trackUrl: '',
    title: 'селёдка под<br />шубой',
    artist: 'Ветерок',
    position: 'top',
    name: 'Ветерок',
    description:
      'Ковбой любви из группы «Ветерок» Пётр Чинават считает Новый год романтическим праздником. По сюжету песни, лирический герой — преодолевая городскую гололедицу — несёт возлюбленной торжественные подношения. Среди них: оливье, маринады, и, конечно, блюдо-намёк — селёдка под шубой. В песне салат отвечает за лихое «уху-уху».',
    videoId: 'ghDlkTAifwg',
    bgColor: '#F0DEAE',
    iconUrl: '',
    infoButtonText: 'о песне',
  },
  {
    trackUrl: '',
    title: 'чёрная икра',
    artist: 'Директор Всего',
    description:
      'У Директора Всего — альтер-эго музыканта Андрея Ли — нормированный, но плотный график. На запрос о съемках в эпизоде «Музыки и Еды» Директор ответил отказом, но передал mp3-файл и небольшое поздравительное сообщение: «Хороших руководителей много не бывает, как и чёрной икры. Эффективного 2021-го».',
    videoId: 'z7vxLNWozr8',
    bgColor: '#000000',
    iconUrl: '',
    position: 'top',
    infoButtonText: 'о песне',
  },
]

export const menuItems = [
  {
    title: 'еда поет',
    onClick: appendContent => appendContent('intro'),
    background: eda,
    color: '#181818',
  },
  {
    title: 'интурист',
    onClick: appendContent => appendContent('video-ZYyzUzr_A_s'),
    background: intourist,
    color: '#181818',
  },
  {
    title: 'ветерок',
    onClick: appendContent => appendContent('video-ghDlkTAifwg'),
    background: chinavat,
    color: '#181818',
  },
  {
    title: 'дима пантюшин',
    onClick: appendContent => appendContent('video-eif7TH7gpjU'),
    background: pant,
    color: '#181818',
  },
  {
    title: 'kate nv',
    onClick: appendContent => appendContent('video-BZqw8Ih1NKU'),
    background: katenv,
    color: '#181818',
  },
  {
    title: 'директор всего',
    onClick: appendContent => appendContent('video-z7vxLNWozr8'),
    background: ceo,
    color: '#181818',
  },
  {
    title: 'создай свой микс',
    onClick: appendContent => appendContent('sampler'),
    color: '#181818',
  },
]

export const videoUrl = BASE_S3_URL + 'edaxPlaytronica.mp4'

export const aboutInfo = {
  header: 'песни для любимой еды',
}

const hipHop = 'hiphop.mp3'
const jazz = 'jazz.mp3'
const jazz2 = 'jazz2.mp3'
const dnb = 'dnb.mp3'
const hipHop2 = 'hiphop2.mp3'

const loop1 = 'loop1.mp3'
const loop2 = 'loop2.mp3'
const loop3 = 'loop3.mp3'
const loop4 = 'loop4.mp3'

export const drumsConfig = [
  {
    title: 'трэп',
    src: loop1,
    name: 'loop1',
  },
  {
    title: 'хаус',
    src: loop2,
    name: 'loop2',
  },
  {
    title: 'техно',
    src: loop3,
    name: 'loop3',
  },
  {
    title: 'брейк',
    src: loop4,
    name: 'loop4',
  },
]
