import React from 'react'
import cn from 'classnames'

import styles from './styles.module.css'
import { menuItems } from '../../config/'
import VideoContainer from '../../components/VideoContainer'
import TrackVideo from '../../components/TrackVideo'
import IntroScreen from '../../components/IntroScreen'
import MenuIcon from '../Icons/Menu'

const Menu = ({
  changeContent,
  isHidden,
  activeItem,
  updateActive,
  setMenuHidden,
}) => {
  function selectContent(content) {
    let contentMapped = {
      sampler: <VideoContainer setMenuHidden={setMenuHidden} />,
      video: (
        <TrackVideo
          setMenuHidden={setMenuHidden}
          videoId={content.replace('video-', '')}
        />
      ),
      intro: (
        <IntroScreen
          changeContent={changeContent}
          setMenuHidden={setMenuHidden}
          updateActive={updateActive}
        />
      ),
    }
    if (content.includes('video')) {
      changeContent(contentMapped['video'])
    } else if (content == 'info') {
      changeContent(contentMapped[content])
    } else {
      changeContent(contentMapped[content])
    }
  }

  const handleClickItem = (item, key) => {
    item.onClick(selectContent)
    updateActive(key)
  }

  return (
    <>
      {isHidden && activeItem > 0 ? (
        <MenuIcon  className={styles.menuToggle} onClick={() => setMenuHidden(false)} />
      ) : (
        <div
          id="menu"
          className={cn(styles.menuContainer, { [styles.hidden]: isHidden })}
        >
          {menuItems.map((item, key) => {
            const isActive = activeItem === key
            const withBack = !!item.background
            const buttonStyle = withBack
              ? {
                  ...( isActive ? { backgroundColor: item.color } : { backgroundImage: `url('${item.background}')` })
              }: { backgroundColor: isActive && item.color }

            return (
              <div key={key} className={styles.menuItem}>
                <button
                  className={cn({
                    [styles.activeButton]: isActive,
                  })}
                  style={buttonStyle}
                  onClick={() => handleClickItem(item, key)}
                >
                  {withBack ? (
                    isActive && <span>{item.title}</span>
                  ) : (
                    <span>{item.title}</span>
                  )}
                </button>
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export default Menu
