import React, { useEffect } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import MainFrame from '../components/MainFrame'
import { toneHelper } from '../utils'

const IndexPage = () => {
  useEffect(() => {
    toneHelper()
    !function(A,n,g,u,l,a,r){A.GoogleAnalyticsObject=l,A[l]=A[l]||function(){
      (A[l].q=A[l].q||[]).push(arguments)},A[l].l=+new Date,a=n.createElement(g),
      r=n.getElementsByTagName(g)[0],a.src=u,r.parentNode.insertBefore(a,r)
      }(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
    
      ga('create', 'UA-106419193-1');
      ga('send', 'pageview');
  }, [])

  return (
    <Layout>
      <SEO title="Еда поёт | Яндекс.Еда x Playtronica" />
      <MainFrame />
    </Layout>
  )
}

export default IndexPage
