import { useState, useEffect } from 'react'

export default () => {
  const [keyPressed, setKeyPressed] = useState(null)

  function downHandler(e) {
    setKeyPressed(e)
  }

  const upHandler = () => {
    setKeyPressed(null)
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  return keyPressed
}
