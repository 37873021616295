import React from 'react'

export default props => (
  <svg
    {...props}
    width="94"
    height="94"
    viewBox="0 0 94 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <circle cx="47" cy="41" r="35" fill="#FFE033" />
    </g>
    <path d="M31.1842 42.1243L63.064 42.1243" stroke="black" strokeWidth="4" />
    <path d="M31.1842 31L63.064 31" stroke="black" strokeWidth="4" />
    <path d="M31.1842 53L63.064 53" stroke="black" strokeWidth="4" />
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="94"
        height="94"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
