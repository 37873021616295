import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import ReactDOM from 'react-dom'
import Fade from 'react-reveal/Fade'
import styles from './styles.module.css'
import { introInfo } from '../../config/index'
import headerImg from '../../images/headerimg.png'
import CrossIcon from '../Icons/CrossIcon'

const About = ({ content, buttonText, onOpen }) => {
  let [opened, openAbout] = useState(false)

  useEffect(
    () => {
      onOpen && onOpen(opened)
    },
    [opened]
  )

  const Modal = ({ children }) => {
    if (typeof document !== 'undefined') {
      const element = document.querySelector('.popup-wrap')
      if (children) {
        'sampler,pads,track-video,player,drums'.split(',').map(item => {
          if (document.getElementById(item)) {
            document.getElementById(item).style.filter =
              'blur(5px) brightness(70%)'
          }
        })
        element && element.classList.remove('hidden')
        return ReactDOM.createPortal(children, element)
      }
      'sampler,pads,track-video,player,drums'.split(',').map(item => {
        if (document.getElementById(item)) {
          document.getElementById(item).style.filter = 'none'
        }
      })
      element && element.classList.add('hidden')
    }
    return null
  }

  return (
    <>
      <div className="overlay hidden"></div>
      <div className="popup-wrap"></div>
      <button
        className={cn(styles.infoButton, {
          [styles.wideButton]: buttonText,
          [styles.cross]: buttonText && opened,
        })}
        onClick={() => (opened ? openAbout(false) : openAbout(true))}
      >
        <span className={styles.i}>
          {buttonText ? opened ? <CrossIcon /> : buttonText : 'i'}
        </span>
      </button>
      <Fade>
        <Modal>
          {opened ? (
              <div className={styles.aboutContainer}>
                {content ? (
                  content
                ) : (
                  <div>
                    <div className={styles.headerImg}>
                      <img src={headerImg} />
                    </div>
                    {introInfo.map((item, key) => {
                      return <p key={key}>{item}</p>
                    })}
                  </div>
                )}
              </div>
          ) : (
            ''
          )}
        </Modal>
      </Fade>
    </>
  )
}

export default About
