import React, { useEffect } from 'react'
import cn from 'classnames'

import TrackVideo from '../TrackVideo'

import { tracksConfig } from '../../config'
import Fade from 'react-reveal/Fade'

import cover from '../../images/intro_cover.png'
import logo from '../../images/dusha_full.png'
import EdaPoet from '../Icons/EdaPoet'
import styles from './style.module.css'

const { videoId } = tracksConfig[0]

const IntroScreen = ({ changeContent, setMenuHidden, updateActive }) => {
  useEffect(() => {
    setMenuHidden(true)

    return () => setMenuHidden(false)
  }, [])

  const handleClick = () => {
    updateActive(1)
    changeContent(
      <TrackVideo setMenuHidden={setMenuHidden} videoId={videoId} />
    )
  }

  return (
    <Fade>
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${cover})` }}
    >
      <div className={styles.overlay}> 
        <div className={styles.logoWrap}>
          <div className={styles.logo}>
            <EdaPoet />
            <p className={styles.logoText}>
              проект <br /> яндекс.еды <br /> и&nbsp;плейтроники
            </p>
          </div>
          
        </div>
        <div className={styles.description}>
          <div className={styles.text}>
            За новогодним столом музыканты вспомнили любимую еду и дали ей голос
            с помощью специального девайса,{' '}
            <a
              className={styles.link}
              href="https://shop.playtronica.com/playtron"
              target="_blank"
            >
              плейтрона
            </a>. Все «исполнители» нашлись в Яндекс.Еде, ведь плейтроном можно
            озвучить и мясную нарезку с оливье, и бутерброды с икрой, и селёдку
            под шубой. Подключайтесь к празднику и вы — создайте свою мелодию на
            музыкальном семплере, в который мы уже добавили звуки и фразы о еде
            из культовых новогодних фильмов.
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <div onClick={handleClick} className={styles.button}>
            триии-четыыыре
          </div>
        </div>
        <div className={styles.bottomText}>
          Яндекс.Еда — информационный сервис заказа еды. Зона, время доставки и предложения ограничены, подробнее на eda.yandex.ru. Доставку осуществляют партнёры сервиса или рестораны (0+). Playtronica — студия креативных технологий, объединяющая развлечения, образование и искусство.
        </div>
      </div>
    </div>
    </Fade>
  )
}

export default IntroScreen
