import React from 'react'

export default props => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2 2L24.5424 24.5424" stroke="black" strokeWidth="4" />
    <path d="M24.5425 2L2.00011 24.5424" stroke="black" strokeWidth="4" />
  </svg>
)
