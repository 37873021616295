import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'
import Menu from '../../components/Menu'
import Fade from 'react-reveal/Fade'
import IntroScreen from '../../components/IntroScreen'

const MainFrame = () => {
  const [content, changeContent] = useState(null)
  const [menuHidden, setMenuHidden] = useState(false)
  const [activeItem, updateActive] = useState(0)

  useEffect(() => {
    changeContent(
      <IntroScreen
        changeContent={changeContent}
        setMenuHidden={setMenuHidden}
        updateActive={updateActive}
      />
    )
  }, [])

  return (
    <Fade>
      <div className={styles.mainFrame}>
        {!activeItem ? (
          <IntroScreen
            changeContent={changeContent}
            setMenuHidden={setMenuHidden}
            updateActive={updateActive}
          />
        ) : (
          <>
            <Menu
              isHidden={menuHidden}
              updateActive={updateActive}
              setMenuHidden={setMenuHidden}
              activeItem={activeItem}
              changeContent={changeContent}
            />
            {content}
          </>
        )}
      </div>
    </Fade>
  )
}

export default MainFrame
