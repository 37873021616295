import React from 'react'
import { timeToSeconds } from '../../../utils'
import { padColors } from '../../../config'
import styles from './styles.module.css'

const Pad = ({ pad, playerHandler, uniqId }) => {
  return (
    <div
      style={{ background: '#FADF4F' }}
      className={styles.pad + ' unselectable'}
      onClick={() => playerHandler(timeToSeconds(pad.time))}
    >
      <span className={styles.label}>{pad.key}</span>
    </div>
  )
}

export default Pad
