import React, { useEffect, useRef } from 'react'
import styles from './styles.module.css'
import cn from 'classnames'
import Fade from 'react-reveal/Fade'
import About from '../../components/About'
import { tracksConfig } from '../../config/index'
import { isSSR } from '../../utils'

let player
let YT

if (typeof window !== `undefined`) {
  YT = window.YT
}

const TrackVideo = ({ videoId, setMenuHidden }) => {
  const iframeRef = useRef(null)
  let aboutConfig = tracksConfig.filter(i => i.videoId == videoId)[0]
  
  let videoAbout = aboutConfig && (
    <div className={cn(styles.videoAbout, { [styles.top]: aboutConfig.position === 'top' })}>
      <h1 dangerouslySetInnerHTML={{ __html: aboutConfig.title }} />
      <h2>{aboutConfig.description}</h2>
    </div>
  )

  useEffect(() => {
    if (!YT) return
    player = new YT.Player('iframe', {
      events: {
        onStateChange: onPlayerStateChange,
      },
    })
  }, [videoId])

  function onPlayerStateChange({ data }) {
    switch (data) {
      case 1:
        setMenuHidden(true)
        break
      case 2:
        setMenuHidden(false)
        break
      default:
        break
    }
  }

  return (
    <>
      
      <div
        id="track-video"
        className={cn(styles.videoContainer, styles.ratioOne, styles.ratio)}
      >
          <iframe
            ref={iframeRef}
            id="iframe"
            title="track"
            src={'https://www.youtube.com/embed/' + videoId + '?enablejsapi=1&modestbranding=1&showinfo=0&controls=0'}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; enablejsapi"
            allowFullScreen
          ></iframe>
      </div>
      <About content={videoAbout} buttonText={aboutConfig.infoButtonText} />
    </>
  )
}

export default TrackVideo
