import React, { useState } from 'react'

import styles from './styles.module.css'
import Pad from './Pad'
import { timeToSeconds } from '../../utils'

const Pads = ({ pads, playerHandler, notify }) => {
  const [padPressed, pressPad] = useState(0)
  const handleClickCta = () => {
    pressPad(1)
    playerHandler(timeToSeconds('00:32'))
    notify()
  }

  return (
    <div id="pads" className={styles.container}>
        <div className={styles.padsWrapper}>
          {pads.map((pad, key) => (
            <Pad
              playerHandler={playerHandler}
              pad={pad}
              key={key}
              uniqId={key}
            />
          ))}
        </div>
    </div>
  )
}

export default Pads
